/**
*
* Variables
*
**/

$columnsWidthSet: (10, 20, 25, 30, 33, 40, 50, 60, 67, 70, 75, 80, 90);

/* Colors */

$colorWhite: #FFF;
$colorOffWhite: #F7F6F4;
$colorOffWhite-75: rgba($colorOffWhite, .75);
$colorNude: #C0AC9A;
$colorLightNude: #E8E1D7;
$colorLightNude2: #DCD0C0;
$colorDarkNude: #9A8A7B;
$colorGreyNude: #3C2E20;
$colorGreyNude-40: rgba($colorGreyNude, .4);
$colorGreyNude-50: rgba($colorGreyNude, .5);
$colorGrey:#2B2B2B;
$colorGrey-58: rgba($colorGrey, .58);
$colorDarkGrey:#25282A;
$colorOffBlack: #181818;
$colorBlack: #000;
$colorBlack-50: rgba($colorBlack, .5);
$colorBrown: #977658;
$colorRed: #d70000;

$colorMain: $colorNude;

$footerFontColor: #EAE8E8;
$colorTransparentBlack: rgba(#000, .1);

$contentLinksDefaultColor: $colorMain;

$socialMediaColors: (
    twitter: #4099ff,
    linkedin: #007bb6,
    facebook: #3b5998,
    googleplus: #dd4b39,
    email: #3bb55e,
    instagram: #e1306c
);

$colors: (
    white: $colorWhite,
    offWhite: $colorOffWhite,
    offWhite-75: $colorOffWhite-75,
    nude: $colorNude,
    lightNude: $colorLightNude,
    greyNude: $colorGreyNude,
    greyNude-40: $colorGreyNude-40,
    greyNude-50: $colorGreyNude-50,
    grey: $colorGrey,
    grey-58: $colorGrey-58,
    darkGrey-58: $colorDarkGrey,
    offBlack: $colorOffBlack,
    black: $colorBlack,
    black-50: $colorBlack-50,
    brown: $colorBrown
);

$bgColorsWhiteFont: (
    nude: $colorNude,
    greyNude-40: $colorGreyNude-40,
    greyNude-50: $colorGreyNude-50,
    grey: $colorGrey,
    grey-58: $colorGrey-58,
    offBlack: $colorOffBlack,
    black: $colorBlack,
    black-50: $colorBlack-50,
    brown: $colorBrown
);

$FontSizeDefault: 18px;
$FontSizeLarge: 24px;
$FontSizeSmall: 16px;
$FontSizeTiny: 14px;
