.popup {
    max-width: 445px;
    padding: 3.5em 4em;
    background-color: #fff;
    text-align: center;

    &--overflow-visble {
        padding-bottom: 10px;
        overflow: visible;

        @media all and (max-width: 574px) {
            overflow-y: auto;
        }
    }

    &--centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        max-width: 520px;
        width: 92%;
        padding: 80px 70px 70px;
    
        max-height: 80vh;
        overflow: auto;
        text-align: left;
    
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;
    }


    &.show {
        opacity: 1;
        visibility: visible;
        z-index: 999;
    }

    @media screen and (max-width: 640px) {
        padding: 50px 30px;
    }

    &__close {
        position: absolute;
        top: 14px;
        right: 14px;
        padding: 6px;
        background-color: transparent;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            outline: 0;
        }

        svg {
            fill: $colorOffBlack;
        }
    }


    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1; //998
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.5);

        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;

        &.show {
            opacity: 1;
            visibility: visible;
            z-index: 998;
        }
    }
}
