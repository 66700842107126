.gallery {
    height: 80vh;
    display: flex;
    flex-wrap: wrap;

    @media all and (min-width: 992px ) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media all and (max-width: 574px) {
        height: 100vh;
    }

    &__image {
        position: relative;
        flex: 1 1 65%;
        width: 25%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: all 0.3s ease;

        @media all and (min-width: 575px) and (max-width: 991px) {
            flex: 1 1 50%;
            height: 25%;
        }

        @media all and (max-width: 574px) {
            flex: 1 1 100%;
            height: 25%;
        }

        &:hover {

            &:after {
                opacity: 0;
                visibility: hidden;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(60,46,32,0.3);
            opacity: 1;
            visibility: visible;
            transition: all 0.2s ease-in-out 0s;
        }

        &:nth-of-type(1) {
            @media all and (min-width: 575px) and (max-width: 991px) {
                order: 1;
            }
        }

        &:nth-of-type(2) {
            @media all and (min-width: 575px) and (max-width: 991px) {
                order: 3;
            }

            @media all and (max-width: 574px) {
                display: none;
            }
        }

        &:nth-of-type(3) {
            @media all and (min-width: 575px) and (max-width: 991px) {
                order: 2;
            }

            @media all and (max-width: 574px) {
                display: none;
            }
        }

        &:nth-of-type(4) {
            @media all and (min-width: 575px) and (max-width: 991px) {
                order: 4;
            }
        }

        &:nth-of-type(5) {
            @media all and (min-width: 575px) and (max-width: 991px) {
                order: 5;
            }

            @media all and (max-width: 574px) {
                display: none;
            }
        }

        &:nth-of-type(6) {
            @media all and (min-width: 575px) and (max-width: 991px) {
                order: 6;
            }
        }

        &:nth-of-type(7) {
            @media all and (min-width: 575px) and (max-width: 991px) {
                order: 7;
            }

            @media all and (max-width: 574px) {
                display: none;
            }
        }

        &:nth-of-type(8) {
            @media all and (min-width: 575px) and (max-width: 991px) {
                order: 8;
            }
        }

        &--small {

            @media all and (min-width: 992px) {
                flex-basis: 35%;
            }
        }

        img {
            display: none;
        }
    }
}