/**
*
* Modal
*
**/
.modal {
    display: none;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 50%;
    z-index: 2;
}