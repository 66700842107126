/**
*
* Main footer
*
**/

.l-footer {
    padding: 60px 0 50px;

    @media all and (max-width: 574px) {
        padding: {
            top: 44px;
            bottom: 54px;
        };
    }

    &__form {

        @media all and (min-width: 992px) {
            margin-right: auto;
            margin-left: auto;
            width: 92%;
            max-width: 635px;
        }

        .btn {
            font-weight: bold;
            font-size: 21px;
            line-height: 1.2;
            cursor: pointer;

            @media all and (min-width: 575px) {
                max-width: 176px;
            }

            @media all and (max-width: 574px) {
                margin-top: 8px;
                width: 100%;
            }
        }

        &-bottom {
            margin: 14px 0 30px;

            @media all and (max-width: 574px) {
                margin-bottom: 38px;
                text-align: center;
            }
        }

        .form__input {
            color: $colorDarkGrey;
        }
    }

    &__main {
        margin-top: 60px;
        padding-top: 30px;
        border-top: 1px solid $colorOffBlack;

        @media all and (min-width: 992px) {
            display: flex;
            justify-content: space-between;
        }

        @media all and (max-width: 574px) {
            padding-top: 43px;
        }

        &--right {
            display: flex;
            flex-direction: column;
            margin-top: auto;

            @media all and (max-width: 991px) {
                position: relative;
                margin-top: 8px;
            }

            p {
                margin: 0;

                @media all and (min-width: 575px) and (max-width: 991px) {
                    margin-top: 20px;
                }

                @media all and (max-width: 574px) {
                    margin-top: 15px;
                }

                span {
                    @media all and (max-width: 574px) {
                        margin-top: 15px;
                        display: block;
                    }
                }
            }
        }
    }

    &__social {
        display: flex;

        @media all and (min-width: 992px) {
            margin-bottom: 20px;
            align-items: center;
            justify-content: flex-end;

        }

        @media all and (min-width: 575px) and (max-width: 991px) {
            flex-wrap: wrap;
        }

        @media all and (max-width: 574px) {
            flex-direction: column;
        }

        @media all and (min-width:575px) and (max-width: 991px) {
            position: absolute;
            top: -32px;
            right: 0;
            flex-direction: column;
            align-items: flex-end;
        }

        @media all and (max-width: 574px) {
            margin-top: 35px;
            flex-direction: column;
        }

        .social-media {
            @media all and (min-width: 575px) and (max-width: 991px) {
                margin-top: 30px;
            }

            @media all and (max-width: 574px) {
                flex-basis: 100%;
                order: -1;
            }

            .social-media__item {

                @media all and (max-width: 991px) {
                    margin-bottom: 30px;
                }

                &:first-child {
                    @media all and (max-width: 991px) {
                        margin-left: 0;
                    }
                }
            }
        }

        .link {
            white-space: nowrap;

            // @media all and (max-width: 574px) {
            //     margin-bottom: 30px;
            // }

            &:not(:first-child) {

                @media all and (max-width: 574px) {
                    margin-top: 5px;
                }

                &:before {
                    content: "|";
                    display: inline-block;
                    padding: 0 10px;

                    @media all and (max-width: 574px) {
                        display: none;
                    }
                }
            }
        } 
    }
}